import React from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

class FullPageIntroWithFixedNavbar extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      labels :[
        {
          title : "Home",
          id :  0,
          href : "#"
        },
        {
          title : "Brand Analytics",
          id :  1,
          href : "#BA"
        },
        {
          title:"Our Vision",
          id:2,
          href:"#FourPs"
        },
        {
          title : "Competitive Intelligence",
          id :  3,
          href : "#CI"
        },
        {
          title : "Why Us",
          id :  4,
          href : "#why-us-id"
        },
        {
          title : "Contact  ",
          id :  5,
          href : "#contact-us"
        },
      ]
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  render() {
    return (
      <div >
        <header>
          <Router className="navbar"  >
            <MDBNavbar   dark expand="md" fixed="top"  scrolling transparent>
              <MDBNavbarBrand className="navbar-logo" href="#">
               <img  src="img/goLogo.png" alt="logo" width="200px"/>
              </MDBNavbarBrand>
              {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.onClick} />}
              <MDBCollapse isOpen={this.state.collapse} navbar>
                <MDBNavbarNav right>
                 {this.state.labels.map((label) =>  (
                   <MDBNavItem key ={label.id}>
                     <a href={label.href}><div className="nav-link">
                       {label.title}
                       </div></a>
                   </MDBNavItem>
                 ))}
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
          </Router>
          </header>
      </div>
    );
  }
}

export default FullPageIntroWithFixedNavbar;