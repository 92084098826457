import React from "react";
import Navbar from "./modules/Navbar";
import { BrandAnalytics } from "./modules/brandAnalytics";
import CompetitiveIntelligence from "./modules/competitiveIntelligence";
import SevenV from "./modules/sevenV";
import ContactUs from "./modules/contactUs";
import Footer from "./modules/footer";
import Carousel from "./modules/Carousel";
// import BrandSlider from "./modules/brandSlider";
import FourPs from "./modules/fourP";
import MapCustomer from './modules/mappingCustomer'
function App() {
  return (
    <div>
      <Navbar />
      <Carousel />
      <BrandAnalytics />
      <FourPs />
      <MapCustomer/>
      <CompetitiveIntelligence />
      <SevenV />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
