/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "../assets/css/style.css";
import { FOUR_PS } from "../common/constant";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
const FourPs = () => {
  return (
    <div id="FourPs" className="p-t-70">
      <div className="p-t-40 comp-l-r-padding display-flex" style={{ backgroundImage: `url('/img/data-driven.png')` }}>
        <div className="fps-details-width margin-auto">
          <div>
            <p className="txt-align-center ff-sans-serif ba-title c-FFFFFF">Data driven approach to optimize your brand's 4P's</p>
          </div>
          <Fade>
            {FOUR_PS.map((item, index) => {
              return (
                <div className="display-flex m-t-70 m-b-40 each-fade display-block-tab">
                  <div className="p-auto margin-auto ba-an-img">
                    <img className="width-100-p " src={`/img/${item.img}`} />
                    <h2 class="overlay-number">{index + 1}</h2>
                  </div>
                  <div className="display-flex ba-details-width p-t-15 display-block-tab" style={{ height: "max-content" }}>
                    <div className="display-flex display-block-mob width-100-p fp-main-details">
                      <div className="width-100-p p-30">
                        <div className="txt-align-start p-l-70">
                          <span className="txt-align-start c-FF8A00 ff-sans-serif fs-26 fw-900">{item.title.charAt(0)}</span>
                          <span className="txt-align-start c-FFFFFF ff-sans-serif fs-26 fw-900">{item.title.slice(1)}</span>
                        </div>
                        <div className="txt-align-start display-flex p-l-70 display-block-mob" style={{ flexWrap: "wrap" }}>
                          {item.details.map((d) => {
                            return (
                              <div className="width-50-p display-flex" id="fp-detail-list">
                                <div>
                                  <img class="check-bullet" src="/img/Check-bullet-point.svg" />
                                </div>
                                <div className="" style={{ margin: 5 }}>
                                  <span className="txt-align-start fw-400 fs-14 c-FFFFFF">{d}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Fade>
        </div>
      </div>
    </div>
  );
};
export default FourPs;
