import React from "react";
import "../assets/css/style.css";

export default function CompetitiveIntelligence() {
  return (
    <div  id="CI" className="p-t-70">
      <div  className="comp-l-r-padding ci-bg">
        <div className="width-100-p txt-align-center p-b-80 p-t-15">
        <p className="fw-600 fs-20 c-FF8A00 ff-open-sans">Market Intelligence as a service</p>
          <p className="txt-align-center ff-sans-serif ba-title">Competitive Intelligence</p>
          <p className="margin-auto c-3B368C fw-400 ba-details">
            Our competitive intelligence product transforms your approach to decision - making, helping you leverage unique competitive pricing,
            promotions, and merchandising insights.
          </p>
        </div>
        <div className="display-flex margin-auto ci-details-width p-t-15 display-block-tab">
          <div className="p-auto margin-auto ba-an-img width-100-p">
            <img className="mb-4 width-100-p" src="/img/phone.jpg" alt="phone" />
          </div>
          <div className="display-flex jc-space-around fl-wrap-wrap p-l-20">
            <div className="shadow ci-card mb-4 bg-white ">
              <div className="CI-icon">
                <img src="/img/CI/1.svg" alt=""></img>
              </div>
              <h4 className=" CI-h4">Competitor's Assortment</h4>
              <p className="CI-p">Benchmarking instock against your eyeball competition</p>
            </div>
            <div className="shadow ci-card  mb-4 bg-white ">
              <div className="CI-icon">
                <img src="/img/CI/2.svg" alt=""></img>
              </div>
              <h4 className=" CI-h4">Share Of Search</h4>
              <p className="CI-p">Evaluating shelf velocity for all top searched keywords</p>
            </div>
            <div className="shadow ci-card  mb-4 bg-white ">
              <div className="CI-icon">
                <img src="/img/CI/3.svg" alt=""/>
              </div>
              <h4 className=" CI-h4">Bestseller trends</h4>
              <p className="CI-p">Reviewing bestseller trends against eyeball competitor</p>
            </div>
            <div className="shadow ci-card  mb-4 bg-white ">
              <div className="CI-icon">
                <img src="/img/CI/4.svg" alt=""/>
              </div>{" "}
              <h4 className=" CI-h4">Share Of Voice</h4>
              <p className="CI-p">Gaining insights about your share of visibility in the brand's relevant category</p>
            </div>
            <div className="shadow ci-card   mb-4 bg-white ">
              <div className="CI-icon">
                <img src="/img/CI/5.svg" alt="" />
              </div>
              <h4 className=" CI-h4">Share Of Shelf</h4>
              <p className="CI-p">Tracking brand's share in the relevant category nodes/ taxonomy</p>
            </div>
            <div className="shadow ci-card   mb-4 bg-white ">
              <div className="CI-icon">
                <img src="/img/CI/6.svg" alt="" />
              </div>
              <h4 className=" CI-h4">Competitive Pricing</h4>
              <p className="CI-p">Benchmarking pricing against eyeball competition</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
