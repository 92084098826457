import React from "react";
import "../assets/css/style.css";
import { BA } from "../common/constant";
import { CI } from "../common/constant";
import { AU } from "../common/constant";

export default function Conatiner8() {
  const BIArr = [];
  for (let i = 0; i < BA.length; i++) {
    BIArr.push(
      <p id="footer-p">
        <a id="footer-a" href="#BA">
          {BA[i]}
        </a>
      </p>
    );
  }

  const CIArr = [];
  for (let i = 0; i < CI.length; i++) {
    CIArr.push(
      <p id="footer-p">
        <a id="footer-a" href="#CI">
          {CI[i]}
        </a>
      </p>
    );
  }
  const AUArr = [];
  for (let i = 0; i < AU.length; i++) {
    AUArr.push(<section>{AU[i]}</section>);
  }
  return (
    <div className="footer p-l-50 p-r-50 p-t-50 p-b-15">
      <div className="row d-flex flex-wrap ml-auto mr-auto">
        <div className="col-lg-3 ">
          <h4 className="footer-links">Brand Analytics</h4>
          {BIArr}
        </div>
        <div className="col-lg-3 ">
          <h4 className="footer-links">Competitive Intelligence</h4>
          {CIArr}
        </div>
        <div className="col-lg-6">
          <h2>
            <img className="footer-logo" src="/img/goLogo.png" alt="footer-logo" width="1000" />
          </h2>

          <div className="p-t-50">
            <h3>Connect with Us</h3>
            <p>Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days</p>
            <hr className="hr-orange"></hr>
            <div className="d-flex flex-wrap">
              <section className="flex-fill">
                <div className="d-flex">
                  <i className="fa fa-phone"></i>
                  <h5>Call Us</h5>
                </div>
                <a href="tel:+91 8591037271">+91 8591037271</a>
              </section>

              <section className="flex-fill">
                <div className="d-flex">
                  <i className="fa fa-envelope"></i>
                  <h5>Mail Us</h5>
                </div>
                <a href="mailto:contact@goperceptive.com">contact@goperceptive.com</a>
              </section>
            </div>

            <div className="d-flex">
              <i className="fa fa-location-arrow"></i>
              <h5>Address</h5>
            </div>
            <p>
              <b>
                {" "}
                CoworkInsta, Tower Block C ( High Rise Tower ),
                <br /> 7th Floor, Supermart 1,
                <br /> DLF Phase - 4, Gurugram - 122009
              </b>
            </p>
          </div>
        </div>
      </div>
      <h6 className="p-t-10">
        Copyright &copy; <strong>GoPerceptive</strong>. All rights reserved{" "}
      </h6>
    </div>
  );
}
