import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/css/custome.css";
import "./assets/css/responsive.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

ReactDOM.render(<App />, document.getElementById("root"));

// var request, url, target_data;
// function movie_seat_shows(target) {
//   target_data = target;
//   url = "";
//   if (window.XMLHttpRequest) {
//     request = new XMLHttpRequest();
//   } 
// //   else if (window.ActiveXObject) {
// //     request = new ActiveXObject("Microsoft.XMLHTTP");
// //   }
//   try {
//     request.onreadystatechange = getInfo2;
//     request.open("GET",url,true);
//     request.send();
//   } catch (e) {
//     alert("Unable to connect to server");
//   }
// }
// function getInfo2() {
//   if (request.readyState === 4) {
//     var val = request.responseText;
//     document.getElementById(target_data).innerHTML = val;
//   } else {
//     document.getElementById(target_data).innerHTML = "ERROR";
//   }
// }
