/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "../assets/css/style.css";
import { BRAND_ANALYSIS } from "../common/constant";
export const BrandAnalytics = () => {
  return (
    <div id="BA" className="p-t-60 comp-l-r-padding">
      <div className="width-100-p txt-align-center p-b-10 p-t-15">
      <p className="fw-600 fs-20 c-FF8A00 ff-open-sans">Core Competency</p>
        <p className="txt-align-center ff-sans-serif ba-title">Brand Analytics</p>
        <p className="margin-auto c-3B368C fw-400 ba-details">
          Consumer brands can derive optimal value from the eCommerce channel by managing how the brand is represented and viewed online hereby
          optimizing the experience delivered to their customers
        </p>
      </div>
      <div className="display-flex margin-auto ba-details-width p-t-15 display-block-tab">
        <div className="p-auto margin-auto ba-an-img">
          <img className="mb-4 width-100-p" src="/img/BA.png" />
        </div>
        <div className="display-flex display-block-mob">
          <div className="ba-detail-left">
            {BRAND_ANALYSIS.LEFT_SECTION.map((item) => {
              return (
                <div className="display-flex m-b-10">
                  <div>
                    <img className="ba-details-icon m-r-20" src={`/img/${item.img}`} />
                  </div>
                  <div className="txt-align-start">
                    <span className="txt-align-start ba-details-title fw-900">{item.title}</span>
                    <p className="txt-align-justify fs-12">{item.details}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ba-detail-right">
            {BRAND_ANALYSIS.RIGHT_SECTION.map((item) => {
              return (
                <div className="display-flex m-b-10">
                  <div>
                    <img className="ba-details-icon m-r-20" src={`/img/${item.img}`} />
                  </div>
                  <div className="txt-align-start">
                    <span className="txt-align-start ba-details-title fw-900">{item.title}</span>
                    <p className="txt-align-justify fs-12">{item.details}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
