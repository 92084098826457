import React from "react";
import "../assets/css/style.css";
import { SEVEN_V } from "../common/constant";
export default function sevenV() {
  return (
    <div id="why-us-id" className="p-t-70">
      <div className="sv-details-width margin-auto why-us display-flex comp-l-r-padding">
        <div className="sv-details-title">
          <p className="fw-600 fs-20 c-FF8A00 ff-open-sans txt-align-center">Why Us?</p>
          <p className="fw-900 fs-26 ff-montserrat c-3B368C">Reasons to adopt our Digital Shelf Analytics platform</p>
        </div>
        <div className="sv-details-img txt-align-center">
          <img className="why-us-img" src="/img/7v-sec.png"></img>
        </div>
      </div>
      <div className="colored-7v">
        <div className="sv-details-width sv-main-box-title ff-montserrat comp-l-r-padding">
          <div>
            <span className="c-FF8A00">7 V's </span>
            <span className="c-FFFFFF">of your tailored Journey</span>
          </div>
        </div>
      </div>
      <div className="display-flex flex-wrap flex-7v sv-details-width margin-auto">
        {SEVEN_V.map((item) => {
          return (
            <div class="flip-card card shadow p-3 mb-5 bg-white rounded sv-box-bg">
              <div class="flip-card-inner txt-align-start">
                <div class="flip-card-front">
                  <img className="why-us-svg" src={`/img/${item.img}`} />
                  <p className="flip-h" style={{ color: `${item.color}` }}>
                    {item.title}
                  </p>
                </div>
                <div class="flip-card-back">
                  <img className="why-us-svg" src={`/img/${item.img}`} />
                  <p style={{ color: `${item.color}` }} className="p-t-15">
                    {item.details}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
