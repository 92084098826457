import React, { Component } from "react";
import "../assets/css/style.css";
// import Axios from "axios";


export default class contactUs extends Component {
  state = {
    on: false,
  };

  toggle = () => {
    this.setState({
      on: !this.state.on,
    });
  };  

  // async submitQuery__(){
  //   let res=await Axios.get('http://139.59.16.33:8002/').then(result=>{
      
  //   })
  // }
  render() {
    return (
      <div id="contact-us">
        <div className="container-7 p-70">
          {this.state.on && (
            <div id="contact" className="demo-request">
              <div className="row">
                <h2 className="demo-h">Contact Us</h2>
                <form id="form-request">
                  <label for="name">Name</label>
                  <input id="Name" type="name" placeholder="John Doe" className="demo-input" required />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label for="name">Email</label>
                  <input id="Email" type="email" placeholder="John Doe@example.com" className="demo-input" required />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label for="name">Phone Number</label>
                  <input id="PhnNo" type="tel" placeholder="XXXXXXXXXXXXX" className="demo-input" required />
                  &nbsp;&nbsp;&nbsp;&nbsp;<br />{" "}
          {/*  eslint-disable-next-line no-undef*/ }
                  <span class="butt" id="submit" type="submit"  onClick={(e) => submitForm(e) && this.toggle}>
                    Submit
                  </span>
                  &nbsp;&nbsp;&nbsp;
                <span class="butt" href="#" onClick={this.toggle}>
                close
                </span>
                </form>
                </div>
                </div>
          )}

          {!this.state.on && (
            <>
              <h1>Get in touch with us or Schedule a demo</h1>
              <p>
                <span class="button" href="#" data-toggle="modal" data-target="#demomodal">
                  Get a demo
                </span>
                <span class="button" onClick={this.toggle}>
                  Let's Talk
                </span>
              </p>
            </>
          )}
          <div
            class="modal fade bd-example-modal-lg"
            id="demomodal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div className="demo-request">
                <i class="fas fa-times fa-2x" data-toggle="modal" data-target="#demomodal"></i><br/>
                  <div className="row">
                    <h2 className="demo-h">Try our product and see the difference</h2>
                    <form id="demo-request">
                      <input id="demo-name" type="name" className="demo-input" placeholder="Full Name" required />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input id="demo-email" type="email" className="demo-input" placeholder="Email Id" required />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input id="demo-number" type="tel" className="demo-input" placeholder="Phone Number" required />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input id="demo-company" type="Name" className="demo-input" placeholder="Company" required />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input id="demo-title" type="Name" className="demo-input" placeholder="Job title" required />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                       {/*  eslint-disable-next-line no-undef*/ }
                      <span class="butt" id="submit-demo" onClick={(e) => demoForm(e)}>
                        Submit
                      </span>
                      <br/>
                      &nbsp;&nbsp;&nbsp;
                      {/* <br/> <span  class="butt" href="#" data-toggle="modal" data-target="#demomodal">close</span> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
