export const BRAND_ANALYSIS = {
  LEFT_SECTION: [
    {
      title: "Brand Governance",
      details: "Optimizing price and promotions to drive margin and growth",
      img: "1.svg",
    },
    {
      title: "Online Discoverability",
      details: "Gaining insights on search & discoverability of your product",
      img: "2.svg",
    },
    {
      title: "Content Integrity",
      details: "Mentoring non-compliance  in content and optimizing to improve product positioning",
      img: "3.svg",
    },
    {
      title: "Seller Analysis",
      details: "Reviewing sellers of your brand on marketplace",
      img: "7.svg",
    },
  ],
  RIGHT_SECTION: [
    {
      title: "Assortment Insights",
      details: "Monitoring online shelf availability and identifying gaps in your assortment",
      img: "4.svg",
    },
    {
      title: "Visibility Audit",
      details: "Tracking execution of the media visibility across e-tailers",
      img: "5.svg",
    },
    {
      title: "Brand Perception",
      details: "Understanding the customer sentiments of your brand",
      img: "6.svg",
    },
  ],
};
export const SEVEN_V = [
  {
    title: "Value",
    details: "Strong review and feedback mechanism helping grow your brand equity online",
    img: "Path 329.svg",
    color: "#F94144",
  },
  {
    title: "Volume",
    details: "Ability to handle large volumes of data; scalable storage capacity",
    img: "Group 421.svg",
    color: "#F08080",
  },
  {
    title: "Veracity",
    details: "Highly Accurate & auditable data",
    img: "Group 424.svg",
    color: "#957BC9",
  },
  {
    title: "Validation",
    details: "The USP of our reporting - Date, time & city stamp screenshot",
    img: "Group 431.svg",
    color: "#90BE6D",
  },
  {
    title: "Variety",
    details: "A one stop solution for all eCommerce initiatives & challenges",
    img: "Group 433.svg",
    // color: "#90BE6D",
    color: "#1B98E0",
  },
  {
    title: "Velocity",
    details: "Ability to effectively balance delivery speed, frequency and storage",
    img: "Group 432.svg",
    color: "#43AA8B",
  },
  {
    title: "Visualization",
    details: "Best in class dashboard driven by consitency in user experience, to analyze data trends",
    img: "Group 1714.svg",
    // color: "#1B98E0",
    color: "#577590",
  },
];
export const FOUR_PS = [
  {
    title: "Product",
    img: "brand.jpg",
    details: ["Catalogue Governance", "Brand Perception", "Sentiment Analysis", "Ratings and Reviews", "Content Integrity"],
  },
  {
    title: "Promotion",
    img: "insights.jpg",
    details: ["Best seller Trends", "Visibility Audit", "Share Of Voice", "Share Of Search"],
  },
  {
    title: "Placement",
    img: "insights.jpg",
    details: [
      "Online Discoverability",
      "Assortment Insights",
      "Geo Stock Compliance",
      "In stock trends",
      "Share of shelf",
      "Out of stock Alerts",
      "Daily stock Compliance",
    ],
  },
  {
    title: "Price",
    img: "insights.jpg",
    details: ["Brand Governance", "Price Trends", "Buy Box Compliance", "Price Violations", "Promotion Compliance"],
  },
];

export const BA = [
  "Brand Governance",
  "Assortment Insights",
  "Online Discoverability",
  "Visibility Audit",
  "Content Integrity",
  "Brand Perception",
  "Seller Analysis",
];
export const MAP_CUSTOMER = [
  [
    {
      title: "Online Discoverability",
      details: ["Organic Search Ranking", "Sponsored Search Ranking", "Placement Compliance"],
    },
    {
      title: "Visibility Audit",
      details: ["Banners/ Assets Audit", "Landing Page Compliance"],
    },
  ],
  [
    {
      title: "Content Integrity",
      details: ["Catalogue Governance", "Content Optimization"],
    },
    {
      title: "Brand Perception",
      details: ["Ratings and Reviews", "Sentimental Analysis"],
    },
  ],
  [
    {
      title: "Brand Governance",
      details: ["Promotion Compliance", "Pricing Trends", "Financial Claims Reconciliation", "Price Violations", "Pricing Trends"],
    },
    {
      title: "Assortment Insights",
      details: ["Daily Geo Stock Compliance", "Opportunity Loss", "In-Stock Trends", "OOS Alerts"],
    },
  ],
];

export const CI = ["Competitor's Assortment", "Share of voice", "Share of search", "Share of shelf", "Bestseller trends", "Competitive pricing"];
export const AU = ["About", "Careers", "Customers", "Our Team"];
