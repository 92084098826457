import React, { Component } from "react";
class Carousel extends Component {
  render() {
    return (
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div
            class="carousel-item active"
            style={{ backgroundImage: `linear-gradient(to right  ,  rgba(0,0,50,1),rgba(128,0,128,0.6),rgba(0,0,0,0)), url('/img/insights.jpg')` }}
          >
            <div class="carousel-caption">
              <h3>Our drivers</h3>
              <br />
              <span>Optimizing 4P's in the ecommerce journey.</span>
            </div>
          </div>
          <div
            class="carousel-item"
            style={{ backgroundImage: `linear-gradient(to right  ,  rgba(0,0,50,1),rgba(128,0,128,0.6),rgba(0,0,0,0)), url('/img/brand.jpg')` }}
          >
            <div class="carousel-caption">
              <h3>Our drivers </h3>
              <br />
              <span>Levaraging brand analytics to win in digital shelf.</span>
            </div>
          </div>
          <div
            class="carousel-item"
            style={{ backgroundImage: `linear-gradient(to right  ,  rgba(0,0,50,1),rgba(128,0,128,0.6),rgba(0,0,0,0)), url('/img/banner-4.jpg')` }}
          >
            <div class="carousel-caption">
              <h3>Our drivers</h3>
              <br />
              <span>Actionable insights on every step of online path to purchase.</span>
            </div>
          </div>
          <div
            class="carousel-item"
            style={{
              backgroundImage: `linear-gradient(to right  ,  rgba(0,0,50,1),rgba(128,0,128,0.6),rgba(0,0,0,0)), url('/img/slider-compatative-insights.jpg')`,
            }}
          >
            <div class="carousel-caption">
              <h3>Our drivers</h3>
              <br />
              <span>Going sustainable competitive advantage.</span>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

export default Carousel;
