/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "../assets/css/style.css";
import { MAP_CUSTOMER } from "../common/constant";
// import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
const MapCustomer = () => {
  let currentShow = "mc0";
  function replaceMap(show) {
    if (currentShow !== show) {
      document.getElementById(currentShow).style.display = "none";
      document.getElementById("TP" + currentShow).style.opacity = 0.1;
    }
    if (window.innerWidth >= 480) {
      document.getElementById(show).style.display = "flex";
    } else {
      document.getElementById(show).style.display = "block";
    }
    document.getElementById("TP" + show).style.opacity = 1;
    currentShow = show;
  }
  return (
    <div id="MAPC" className="p-t-70  MAPC-bg">
      <div className="p-t-40 comp-l-r-padding display-flex" style={{ background: "rgba(0,0,0,0.4)" }}>
        <div className="fps-details-width margin-auto">
          <div className="txt-align-center">
            <p className="fw-600 fs-20 c-FF8A00 ff-open-sans">Mapping consumer Journey</p>
            <p className=" ff-sans-serif ba-title c-FFFFFF">Integrating Online Path To Purchase</p>
          </div>
          <div className="display-flex m-t-30 m-b-40 each-fade display-block-tab">
            <div className="p-auto margin-auto mc-an-img">
              <div id="TPmc0" onClick={() => replaceMap("mc0")} className="cr-pointer">
                <div id="map-cons-bot-box">
                  <div className="trapezoid-top trapezoid-box-br-l-r margin-auto p-10"></div>
                </div>
                <div id="map-cons-top-box">Awareness</div>
              </div>
              <div id="TPmc1" onClick={() => replaceMap("mc1")} className="cr-pointer">
                <div id="map-cons-bot-box">
                  <div className="trapezoid-mid trapezoid-box-br-l-r margin-auto p-10"></div>
                </div>
                <div id="map-cons-top-box">Consideration</div>
              </div>
              <div id="TPmc2" onClick={() => replaceMap("mc2")} className="cr-pointer">
                <div id="map-cons-bot-box">
                  <div className="trapezoid-bottom trapezoid-box-br-l-r margin-auto p-10"></div>
                </div>
                <div id="map-cons-top-box">Conversion</div>
              </div>
            </div>
            <div
              className="ba-details-width margin-auto display-flex display-block-tab"
              style={{ height: "max-content" }}
              onLoad={() => replaceMap("mc0")}
            >
              {MAP_CUSTOMER.map((lists, index) => {
                console.log("index", index);
                return (
                  <div className="display-flex width-100-p mc-main-details" id={`mc${index}`} style={{ display: "none" }}>
                    {lists.map((list) => {
                      return (
                        <div className="width-100-p p-30">
                          <div className="txt-align-start">
                            <span className="mc-box-title c-FFFFFF">{list.title}</span>
                          </div>
                          <div className="txt-align-start display-block-mob" style={{ flexWrap: "wrap" }}>
                            {list.details.map((d) => {
                              return (
                                <div className="display-flex" id="fp-detail-list">
                                  <div>
                                    <img class="check-bullet" src="/img/Check-bullet-point.svg" />
                                  </div>
                                  <div className="" style={{ margin: 5 }}>
                                    <span className="txt-align-start fw-400 fs-14 c-FFFFFF">{d}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MapCustomer;
